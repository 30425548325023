export function formatNumber(amount) {
  amount = Math.floor(amount);
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatPercentage(amount) {
  return amount.toFixed(2);
}

export function formatNumberOrPercentage(n) {
  if(n % 1 === 0){
    return formatNumber(parseFloat(n));
  }else{
    return formatPercentage(parseFloat(n));
  }
}

export function formatCurrency(amount) {
  return formatNumber(amount);
}

export function formatCurrencyDivideBy1000(amount) {
  const displayAmount = amount / 1000;
  return formatCurrency(displayAmount);
}

export function formatNumberDivideBy1000(amount) {
  const displayAmount = amount / 1000;
  return formatNumber(displayAmount);
}

export function formatDecimal(amount) {
  if (isNaN(amount) || !amount) {
    return "0";
  }
  const [wholeNumber, decimals] = amount.toString().split(".");
  const result = wholeNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return decimals ? `${result}.${decimals}` : result;
}

export function formatNumberWithDecimal2Places(amount) {
  const returnAmount = (Math.round(amount * 100) / 100).toFixed(2);
  
  return formatDecimal(returnAmount);
}
