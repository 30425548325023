import "./DropdownFilter.scss";

import { Dropdown } from "semantic-ui-react";
import React from "react";
import { StandardSmallHeading } from "../../common/StandardSmallHeading/StandardSmallHeading";

export const DropDownFilter = ({
  options,
  currentSelected,
  onChange,
  header,
  loading = false,
  defaultValue,
}) => {
  return (
    <>
      <StandardSmallHeading>{header}</StandardSmallHeading>
      <Dropdown
        className="filter-dropdown"
        loading={loading}
        fluid
        selection
        options={options}
        value={options.find((x) => x.key === currentSelected?.id)?.value}
        onChange={(_, { value }) => onChange(value)}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default DropDownFilter;
