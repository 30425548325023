import "./AppLayout.scss";
import "react-toastify/dist/ReactToastify.min.css";

import * as Unicons from "@iconscout/react-unicons";

import {
  Container,
  Dropdown,
  Grid,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { setUserNameCache, userNameCache } from "../../lib/authentication";

import { Footer } from "./Footer";
import { Link } from "react-router-dom";
import { MenuContent } from "./MenuContent";
import { MobileMenu } from "./MobileMenu";
import { NotificationsContainer } from "../Notifications/NotificationsContainer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { SpsLoginContext } from "../../modules/login/SpsLoginContext";
import { StandardHeading } from "../../common/StandardHeading/StandardHeading";
import { ToastContainer } from "react-toastify";
import { TokenRefreshHandler } from "../TokenRefreshHandler/TokenRefreshHandler";
import logo from "../../media/debeers-logo-wide-white.png";
import { tokenUserPermissions } from "../../lib/tokenUserPermissions";
import { validateRole } from "../../lib/validateRole";
import { withRouter } from "react-router-dom";

const AppLayout = ({ children, location, history }) => {
  const [userName, setUserName] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const { spsLogin } = useContext(SpsLoginContext);

  const allowMobile = process.env.REACT_APP_ALLOW_MOBILE === "True";
  const allowInsight = process.env.REACT_APP_ALLOW_INSIGHTS === "True";

  const bodyContent = () => {
    return (
      <div className="content-main">
        <Segment basic>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row className="page-content-container">
                <ToastContainer />
                {children}
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  };

  const usernameAsync = async (usernameSubscription, setUserName) => {
    let name = userNameCache();
    if (!name) {
      await setUserNameCache();
      name = userNameCache();
    }

    if (usernameSubscription.isSubscribed) {
      setUserName(name);
    }
  };

  useEffect(() => {
    const usernameSubscription = { isSubscribed: true };
    if (spsLogin.isLoggedIn) {
      usernameAsync(usernameSubscription, setUserName);
    }
    return () => {
      usernameSubscription.isSubscribed = false;
    };
  }, [spsLogin]);

  const handleLogout = () => {
    setUserName("");
    history.push("/logout");
  };

  return (
    <ScrollToTop>
      <div
        className={
          "full-width-background " +
          (location.pathname === "/"
            ? "login"
            : location.pathname.replace("/", ""))
        }
      >
        <Menu className="top-nav-bar-container">
          <Container>
            <Menu.Menu position="left">
              <Menu.Item className="first-item">
                {validateRole(tokenUserPermissions.CUSTOMER_PORTAL) && (
                  <div className="menu-button">
                    <Icon
                      name="bars"
                      onClick={() => setShowSidebar(!showSidebar)}
                    />
                  </div>
                )}
                <div className="company-logo">
                  <Image src={logo} size="medium" />
                </div>
              </Menu.Item>
              {!spsLogin.isPending && spsLogin.isLoggedIn && <MenuContent />}
            </Menu.Menu>
            {!spsLogin.isPending && spsLogin.isLoggedIn && (
              <Menu.Menu position="right">
                <Menu.Item className="notification-pop-up-menu-item">
                  <NotificationsContainer />
                </Menu.Item>
                <div className="side-menu-display desktop hide-smaller-view">
                  {userName && (
                    <Dropdown item text={userName}>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => history.push("/profile")}>
                          <Link to="/profile">
                            <Unicons.UilUser
                              size={16}
                              className="header-dropdown-option-icon"
                            />
                            <span>Profile</span>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push("/finance")}>
                          <Link to="/finance">
                            <Unicons.UilMoneyStack
                              size={16}
                              className="header-dropdown-option-icon"
                            />
                            <span>Financial Submissions</span>
                          </Link>
                        </Dropdown.Item>
                        {(validateRole(
                          tokenUserPermissions.MANUFACTURING_DATA.GET
                        ) ||
                          validateRole(
                            tokenUserPermissions.BOX_INSIGHTS.GET
                          )) &&
                          allowInsight && (
                            <Dropdown.Item
                              onClick={() => history.push("/insights")}
                            >
                              <Link to="/insights">
                                <Unicons.UilTelescope
                                  size={16}
                                  className="header-dropdown-option-icon"
                                />
                                <span>Insights</span>
                              </Link>
                            </Dropdown.Item>
                          )}
                        {validateRole(
                          tokenUserPermissions.VIEW_EMBEDDED_REPORTS.GET
                        ) && (
                            <Dropdown.Item
                              onClick={() => history.push("/reports")}
                            >
                              <Link to="/reports">
                                <Unicons.UilChartLine
                                  size={16}
                                  className="header-dropdown-option-icon"
                                />
                                <span>Reports</span>
                              </Link>
                            </Dropdown.Item>
                          )}
                        <Dropdown.Item onClick={() => handleLogout()}>
                          <Link to="/logout">
                            <Unicons.UilSignOutAlt
                              size={16}
                              className="header-dropdown-option-icon"
                            />
                            <span>Logout</span>
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="mobile-side-menu-display mobile-view mobile smaller-view">
                  {userName && (
                    <Dropdown
                      item
                      text={userName?.split(" ")?.map((word) => {
                        return word.substring(0, 1);
                      })}
                    >
                      <Dropdown.Menu className="profile-menu">
                        <Dropdown.Item onClick={() => history.push("/profile")}>
                          <Link to="/profile">
                            <Unicons.UilUser
                              size={16}
                              className="header-dropdown-option-icon"
                            />
                            <span>Profile</span>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push("/finance")}>
                          <Link to="/finance">
                            <Unicons.UilMoneyStack
                              size={16}
                              className="header-dropdown-option-icon"
                            />
                            <span>Financial Submissions</span>
                          </Link>
                        </Dropdown.Item>
                        {validateRole(
                          tokenUserPermissions.MANUFACTURING_DATA.GET
                        ) && allowInsight && (
                          <Dropdown.Item
                            onClick={() => history.push("/insights")}
                          >
                            <Link to="/insights">
                              <Unicons.UilTelescope
                                size={16}
                                className="header-dropdown-option-icon"
                              />
                              <span>Insights</span>
                            </Link>
                          </Dropdown.Item>
                        )}
                        {validateRole(
                          tokenUserPermissions.VIEW_EMBEDDED_REPORTS.GET
                        ) && (
                          <Dropdown.Item
                            onClick={() => history.push("/reports")}
                          >
                            <Link to="/reports">
                              <Unicons.UilChartLine
                                size={16}
                                className="header-dropdown-option-icon"
                              />
                              <span>Reports</span>
                            </Link>
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => handleLogout()}>
                          <Link to="/logout">
                            <Unicons.UilSignOutAlt
                              size={16}
                              className="header-dropdown-option-icon"
                            />
                            <span>Logout</span>
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              </Menu.Menu>
            )}
          </Container>
        </Menu>
        <Container
          className={
            "content-background-color " +
            (!allowMobile ? "main-content-hide-mobile" : "")
          }
        >
          <div className="content-grid-container side-menu-display">
            {bodyContent()}
          </div>
        </Container>
        {!allowMobile && (
          <Segment compact padded="very" className="mobile-message">
            <StandardHeading>
              Tablet, mobile and smaller screen view coming soon.
            </StandardHeading>
            <p>
              Please increase the size of your browser window, increase your
              screen resolution or use a larger device.
            </p>
          </Segment>
        )}
        <div className="desktop">
          <Footer />
        </div>
        {!spsLogin.isPending && spsLogin.isLoggedIn && (
          <Sidebar.Pushable className="mobile-side-menu-display">
            <MobileMenu
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
            <Sidebar.Pusher
              dimmed={showSidebar}
              onClick={() => setShowSidebar(false)}
            ></Sidebar.Pusher>
          </Sidebar.Pushable>
        )}
      </div>
      <TokenRefreshHandler />
    </ScrollToTop>
  );
};

export default withRouter(AppLayout);
