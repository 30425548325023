import React from "react";
import {
  Table,
  Pagination,
  Dropdown,
  Menu,
  Button,
  Form,
  Transition,
  Icon,
  Select,
  Grid,
} from "semantic-ui-react";
import { ReactComponent as CustomFilterIcon } from "../../media/custom-filter-icon.svg";
import { RenderRowComponent } from "./RenderRowComponent.jsx";
import { DebounceInput } from "react-debounce-input";
import "./IndexPageTable.scss";
import { ceil, uniqWith } from "lodash";
import { ErrorMessage } from "formik";
import { LoaderComponent } from "../LoaderComponent";

const stripIgnoredKeys = (keys, ignoredKeys) => {
  const keysToDisplay = keys.filter(
    (key) => !ignoredKeys.includes(key.toUpperCase())
  );
  return keysToDisplay;
};

const getKeysOnly = (tableData, fieldsToIgnore, fieldNameMappings) => {
  const unsortedObjectKeys = stripIgnoredKeys(
    Object.keys(tableData[0]),
    fieldsToIgnore
  );
  const objectKeysWithOrder = unsortedObjectKeys.map((unsortedObjectKey) => ({
    key: unsortedObjectKey,
    order: fieldNameMappings[unsortedObjectKey.toUpperCase()].order,
  }));
  const sortedObjectKeysWithOrder = objectKeysWithOrder.sort(
    (x, y) => x.order - y.order
  );
  const objectKeys = sortedObjectKeysWithOrder.map((x) => x.key);
  objectKeys.push("actions"); //sadness TODO: do this in a more obvious and readable way
  return objectKeys;
};

const getKeysWithFieldNames = (
  tableData,
  fieldsToIgnore,
  fieldNameMappings
) => {
  const objectKeys = Object.keys(tableData[0]);
  const keys = stripIgnoredKeys(objectKeys, fieldsToIgnore);

  const keysWithFieldNames = keys.map((key) => {
    const data = fieldNameMappings[key.toUpperCase()];
    if (!data) {
      throw new Error(`${key} does not exist`);
    }
    return {
      key: key,
      fieldName: data.name,
      order: data.order,
      cssClass: data.cssClass,
    };
  });
  const sortedKeys = keysWithFieldNames.sort((x, y) => x.order - y.order);
  sortedKeys.push("actions");
  return sortedKeys;
};

const getKeysDefaultBehaviour = () =>
  ErrorMessage("Please specify a behaviour for get keys function");

const getKeysFunctionObject = {
  keys: getKeysOnly,
  keysWithFieldNames: getKeysWithFieldNames,
  default: getKeysDefaultBehaviour,
};

const getKeysBehaviourEnum = {
  keys: "keys",
  keysWithFieldNames: "keysWithFieldNames",
};

const getKeys = (
  tableData,
  fieldNameMappings,
  fieldsToIgnore,
  getKeysBehaviourEnumValue
) => {
  if (tableData && tableData[0]) {
    const keys =
      getKeysFunctionObject[getKeysBehaviourEnumValue](
        tableData,
        fieldsToIgnore,
        fieldNameMappings
      ) || getKeysFunctionObject["default"]();
    return keys;
  } else {
    return ["Description"];
  }
};

const buildHeader = ({
  tableData,
  fieldNameMappings,
  fieldsToIgnore,
  sortColumn,
  sortDirection,
  onSortColumnChange,
  actionsAreDefined,
  fieldNameGroups,
  numberFields,
  percentageFields,
}) => {
  let keys = getKeys(
    tableData,
    fieldNameMappings,
    fieldsToIgnore,
    getKeysBehaviourEnum.keysWithFieldNames
  );
  const indexTableDataNotInitialised = keys[0] === "Description";
  if (indexTableDataNotInitialised) {
    return null;
  }
  if (!actionsAreDefined) {
    keys = keys.filter((x) => x !== "actions");
  }
  if (fieldNameGroups) {
    let firstRowkeyFieldMap = keys.map((key) => {
      const upperCaseKey = key.key ? key.key.toUpperCase() : key.toUpperCase();
      let fieldName = key.fieldName ? key.fieldName : "Actions";
      let isGrouped = false;
      const matchingFieldNameGroup = fieldNameGroups.find((x) =>
        x.fieldNames.includes(upperCaseKey)
      );
      if (matchingFieldNameGroup) {
        fieldName = matchingFieldNameGroup.groupFieldName;
        isGrouped = true;
      }
      return {
        key: key.key ? key.key : key,
        groupName: fieldName,
        isGrouped: isGrouped,
      };
    });

    function uniqueGroupNameComparator(arrVal, othVal) {
      return arrVal.groupName === othVal.groupName;
    }

    firstRowkeyFieldMap = uniqWith(
      firstRowkeyFieldMap,
      uniqueGroupNameComparator
    );

    const firstRowContent = firstRowkeyFieldMap.map((keyFieldMap) => {
      if (!keyFieldMap.isGrouped) {
        return (
          <Table.HeaderCell
            rowSpan="2"
            key={keyFieldMap.groupName}
            sorted={sortColumn === keyFieldMap.key ? sortDirection : null}
            onClick={onSortColumnChange(keyFieldMap.key)}
            className="index-table-header-cell"
          >
            {keyFieldMap.groupName}
          </Table.HeaderCell>
        );
      } else {
        return (
          <Table.HeaderCell
            colSpan="2"
            key={keyFieldMap.groupName}
            className="grouped-column-parent index-table-header-cell"
          >
            {keyFieldMap.groupName}
          </Table.HeaderCell>
        );
      }
    });

    const firstRow = <Table.Row>{firstRowContent}</Table.Row>;
    const keysInFieldNameGroups = keys.filter((x) =>
      fieldNameGroups.find((y) =>
        y.fieldNames.includes(x.key ? x.key.toUpperCase() : x.toUpperCase())
      )
    );
    const secondRowKeyFieldMap = keysInFieldNameGroups.map((key) => {
      return { key: key.key, fieldName: key.fieldName };
    });
    const secondRowContent = secondRowKeyFieldMap.map((keyFieldMap) => {
      return (
        <Table.HeaderCell
          key={keyFieldMap.key}
          sorted={sortColumn === keyFieldMap.key ? sortDirection : null}
          onClick={onSortColumnChange(keyFieldMap.key)}
          className="grouped-column-child"
        >
          {keyFieldMap.fieldName}
        </Table.HeaderCell>
      );
    });

    const secondRow = <Table.Row>{secondRowContent}</Table.Row>;

    const content = (
      <Table.Header>
        {firstRow}
        {secondRow}
      </Table.Header>
    );

    return content;
  }
  const tableHeaders = keys.map((keyFieldMap) => {
    let headerSuffix = "";

    if (
      numberFields &&
      keyFieldMap.key &&
      numberFields.includes(keyFieldMap.key.toUpperCase())
    ) {
      headerSuffix = " ($)";
    } else if (
      percentageFields &&
      keyFieldMap.key &&
      percentageFields.includes(keyFieldMap.key.toUpperCase())
    ) {
      headerSuffix = " (%)";
    }

    const fieldName = keyFieldMap.fieldName ? keyFieldMap.fieldName : "Actions";

    return (
      <Table.HeaderCell
        key={keyFieldMap.key ? keyFieldMap.key : keyFieldMap}
        sorted={sortColumn === keyFieldMap.key ? sortDirection : null}
        onClick={onSortColumnChange(keyFieldMap.key)}
        className={keyFieldMap.cssClass}
      >
        {keyFieldMap.fieldName || fieldName}
        {headerSuffix}
      </Table.HeaderCell>
    );
  });

  const tableRow = <Table.Row>{tableHeaders}</Table.Row>;

  const content = <Table.Header>{tableRow}</Table.Header>;
  return content;
};

const buildFooter = ({
  tableData,
  fieldNameMappings,
  fieldsToIgnore,
  pageNumberUpdate,
  pageSizeUpdate,
  pageSize,
  pageSizeOptions,
  pageNumber,
  totalResults,
  actionsAreDefined,
}) => {
  const keys = getKeys(
    tableData,
    fieldNameMappings,
    fieldsToIgnore,
    getKeysBehaviourEnum.keys
  );
  const numberOfColumnsDisplayed = keys.length;
  const colspanOfPagination = numberOfColumnsDisplayed - 1;
  return (
    <Table.Row>
      <Table.HeaderCell colSpan="2" textAlign={"left"}>
        <Menu compact>
          <Dropdown
            selection
            options={pageSizeOptions}
            onChange={pageSizeUpdate}
            closeOnChange={true}
            value={pageSize}
          />
        </Menu>
      </Table.HeaderCell>
      <Table.HeaderCell
        colSpan={colspanOfPagination - 1}
        textAlign={"right"}
        className="no-left-border"
      >
        <Pagination
          activePage={pageNumber}
          boundaryRange={1}
          onPageChange={pageNumberUpdate}
          size="mini"
          siblingRange={1}
          totalPages={ceil(totalResults / pageSize)}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
        />
      </Table.HeaderCell>
    </Table.Row>
  );
};

const renderRows = (
  tableData,
  fieldNameMappings,
  fieldsToIgnore,
  actionPaths,
  actionIdField,
  dateFields,
  numberFields,
  showCurrency,
  divideby1000NumberFields,
  percentageFields,
  rendererFields,
  rowSpanFields,
  hideActions,
  rowSpan,
  rowSpanInclude
) => {
  if (tableData && tableData.length > 0) {
    let rowSpanDictionary = null;
    let rowSpanIndexDictionary = null;

    if (rowSpan && rowSpan.length > 0) {
      rowSpanDictionary = {};
      rowSpanIndexDictionary = {};
      tableData.forEach((row, index) => {
        rowSpan.forEach((span) => {
          if (rowSpanDictionary[span] === undefined) {
            rowSpanDictionary[span] = [];
            const newEntry = {};
            newEntry[row[span]] = 0;
            rowSpanDictionary[span].push(newEntry);

            rowSpanIndexDictionary[span] = new Array(tableData.length);
            rowSpanIndexDictionary[span][index] = newEntry;
          }
          if (
            Object.keys(
              rowSpanDictionary[span][rowSpanDictionary[span].length - 1]
            )[0] === row[span].toString()
          ) {
            rowSpanDictionary[span][rowSpanDictionary[span].length - 1][
              row[span]
            ] += 1;
          } else {
            const newEntry = {};
            newEntry[row[span]] = 1;
            rowSpanDictionary[span].push(newEntry);
            rowSpanIndexDictionary[span][index] = newEntry;
          }
        });
      });
    }

    var keys = getKeys(
      tableData,
      fieldNameMappings,
      fieldsToIgnore,
      getKeysBehaviourEnum.keys
    );
    return tableData.map((row, index) => {
      return (
        <Table.Row key={index}>
          <RenderRowComponent
            key={index}
            tableRowIndex={index}
            data={row}
            keys={keys}
            actions={actionPaths}
            actionId={actionIdField}
            dateFields={dateFields}
            numberFields={numberFields}
            showCurrency={showCurrency}
            divideby1000NumberFields={divideby1000NumberFields}
            percentageFields={percentageFields}
            rendererFields={rendererFields}
            rowSpanFields={rowSpanFields}
            fieldNameMappings={fieldNameMappings}
            hideActions={hideActions}
            rowSpan={rowSpan}
            rowSpanIndexDictionary={rowSpanIndexDictionary}
            rowSpanInclude={rowSpanInclude}
          />
        </Table.Row>
      );
    });
  } else {
    return (
      <Table.Row>
        <Table.Cell>No results found</Table.Cell>
      </Table.Row>
    );
  }
};

const renderSearchFilters = (
  searchFilters,
  searchFieldNames,
  searchFieldAliasList,
  onFilterChange,
  loading
) => {
  let searchFieldAlias = {};
  let filterFieldAlias = "";

  return searchFilters.map((searchFilter, index) => {
    if (searchFieldAliasList) {
      searchFieldAlias = searchFieldAliasList.find(
        (x) => x.searchFieldName === searchFieldNames[index]
      );
      filterFieldAlias = searchFieldAlias
        ? searchFieldAlias.alias
        : searchFieldNames[index];
    } else {
      filterFieldAlias = searchFieldNames[index];
    }

    return (
      <DebounceInput
        key={searchFieldNames[index]}
        minLength={1}
        debounceTimeout={500}
        element={Form.Input}
        id={searchFieldNames[index]}
        label={filterFieldAlias}
        className="filter-field"
        onChange={(event) =>
          onFilterChange(
            searchFieldNames[index].charAt(0).toLowerCase() +
              searchFieldNames[index]
                .replace(/ /g, "")
                .substring(1, searchFieldNames[index].length),
            event.target.value
          )
        }
        value={searchFilter}
        loading={loading}
      />
    );
  });
};

const renderDropDownFilters = (onFilterChange, dropDownFilters) => {
  return dropDownFilters.map((dropDownFilter) => {
    return (
      <div className="filter-field" key={dropDownFilter.filter.filterProp}>
        <label>{dropDownFilter.filter.label}</label>
        <Select
          options={dropDownFilter.filter.options}
          loading={dropDownFilter.loading}
          search={dropDownFilter.filter.search}
          clearable={dropDownFilter.filter.clearable}
          onChange={(_, { value }) =>
            onFilterChange(dropDownFilter.filter.filterProp, value)
          }
          closeOnChange={true}
          value={dropDownFilter.value}
        />
      </div>
    );
  });
};

export const IndexPageTable = ({
  loading,
  tableData,
  splitTableData,
  summaryRow,
  fieldsToIgnore,
  fieldNameMappings,
  fieldNameGroups,
  rendererFields,
  rowSpanFields,
  dateFields,
  numberFields,
  actionPaths,
  actionIdField,
  pageNumberUpdate,
  pageSizeUpdate,
  pageSize,
  pageNumber,
  totalResults,
  sortColumn,
  sortDirection,
  showFilters,
  onSortColumnChange,
  onFilterChange,
  onShowFiltersChange,
  searchFilters,
  searchFieldNames,
  searchFieldAliasList,
  showCurrency = true,
  divideby1000NumberFields,
  dropDownFilters,
  percentageFields,
  header,
  options,
  rowSpan,
  rowSpanInclude,
  headerContent,
  customFooter,
}) => {
  const pageSizeOptions = [
    { key: 0, text: "5", value: 5 },
    { key: 1, text: "10", value: 10 },
    { key: 2, text: "25", value: 25 },
    { key: 3, text: "50", value: 50 },
    { key: 4, text: "100", value: 100 },
  ];
  const actionsAreDefined = actionPaths && actionIdField;

  const fallbackToUseSplitDataHeadersIfDataTableIsEmpty = () => {
    if (tableData && tableData.length > 0) {
      return tableData;
    } else if (splitTableData && splitTableData.length > 0) {
      return splitTableData[0].data;
    }
    return tableData;
  };

  const onlyRenderSplitTableIfTableDataHasValuesOrIfSplitTableDataHasValues = () => {
    return (
      splitTableData &&
      splitTableData.length > 0 &&
      splitTableData[0].data &&
      ((tableData && tableData.length > 0) || splitTableData[0].data.length > 0)
    );
  };

  const buildHeaderOptions = {
    tableData: fallbackToUseSplitDataHeadersIfDataTableIsEmpty(),
    fieldNameMappings,
    fieldsToIgnore,
    sortColumn,
    sortDirection,
    onSortColumnChange,
    actionsAreDefined,
    fieldNameGroups,
    numberFields,
    percentageFields,
  };

  const buildFooterOptions = {
    tableData,
    fieldNameMappings,
    fieldsToIgnore,
    pageNumberUpdate,
    pageSizeUpdate,
    pageSize,
    pageSizeOptions,
    pageNumber,
    totalResults,
    actionsAreDefined,
  };

  const filterContent = (
    <Grid columns={16}>
      {headerContent || (
        <Grid.Row className="table-header">
          <Grid.Column width={(options && options.headerWidth) || 4}>
            <div className="ipt-header-text">{header}</div>
          </Grid.Column>
          <Grid.Column width={(options && options.filterWidth) || 12}>
            {(searchFilters || dropDownFilters) && (
              <div className="filter-section">
                <div className="filter-fields-index-table">
                  {!showFilters && (
                    <Button
                      id="filter-button"
                      basic
                      secondary
                      onClick={onShowFiltersChange}
                      loading={loading}
                      disabled={loading}
                    >
                      <div className="button-text">Filter</div>
                      <CustomFilterIcon className="button-icon" />
                    </Button>
                  )}
                  {showFilters && (
                    <Button
                      id="filter-button"
                      basic
                      secondary
                      onClick={onShowFiltersChange}
                      loading={loading}
                      disabled={loading}
                    >
                      <CustomFilterIcon className="button-icon-clicked" />
                    </Button>
                  )}
                </div>
                <div className="filter-fields-index-table">
                  <Transition.Group animation={"slide left"} duration={500}>
                    {showFilters &&
                      searchFilters &&
                      renderSearchFilters(
                        searchFilters,
                        searchFieldNames,
                        searchFieldAliasList,
                        onFilterChange,
                        loading
                      )}
                    {showFilters &&
                      dropDownFilters &&
                      renderDropDownFilters(onFilterChange, dropDownFilters)}
                  </Transition.Group>
                </div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );

  const footer = () => {
    if (customFooter) {
      return customFooter;
    }
    if (options && options.disablePagination) {
      return null;
    } else {
      return <Table.Footer>{buildFooter(buildFooterOptions)}</Table.Footer>;
    }
  };

  const content = (
    <React.Fragment>
      {filterContent}
      {
        <LoaderComponent loading={loading}>
          <Table sortable selectable celled>
            {buildHeader(buildHeaderOptions)}
            <Table.Body>
              {renderRows(
                tableData,
                fieldNameMappings,
                fieldsToIgnore,
                actionPaths,
                actionIdField,
                dateFields,
                numberFields,
                showCurrency,
                divideby1000NumberFields,
                percentageFields,
                rendererFields,
                rowSpanFields,
                options && options.hideActionsInTable,
                rowSpan,
                rowSpanInclude
              )}
            </Table.Body>
            {onlyRenderSplitTableIfTableDataHasValuesOrIfSplitTableDataHasValues() &&
              splitTableData.map((splitTable, index) => (
                <React.Fragment key={index}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan={
                          Object.keys(fieldNameMappings).length +
                          (actionIdField && actionPaths ? 1 : 0)
                        }
                      >
                        {splitTable.heading}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderRows(
                      splitTable.data,
                      fieldNameMappings,
                      fieldsToIgnore,
                      actionPaths,
                      actionIdField,
                      dateFields,
                      numberFields,
                      showCurrency,
                      divideby1000NumberFields,
                      percentageFields,
                      rendererFields,
                      rowSpanFields,
                      options && options.hideActionsInSplitTable,
                      rowSpan,
                      rowSpanInclude
                    )}
                  </Table.Body>
                </React.Fragment>
              ))}
            {summaryRow && tableData && tableData.length > 0 && (
              <Table.Header>
                <Table.Row className="footer">
                  {summaryRow.map((summary, index) => (
                    <Table.HeaderCell
                      key={index}
                      colSpan={summary.colSpan || 1}
                      textAlign={summary.textAlign || "left"}
                      className="sticky"
                    >
                      {summary.value}
                    </Table.HeaderCell>
                  ))}

                  {actionsAreDefined && <Table.HeaderCell className="sticky"></Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
            )}

            {footer()}
          </Table>
        </LoaderComponent>
      }
    </React.Fragment>
  );

  return content;
};

export default IndexPageTable;
